import { useEffect } from "react";
import { App, URLOpenListenerEvent } from '@capacitor/app';
// import { useHistory } from "react-router-dom";

console.log('AppUrlListener');
const AppUrlListener: React.FC<any> = () => {
    // let history = useHistory();
    useEffect(() => {
        if ((window as any).cordova) {
            console.log('Is Capacitor App');
            console.log('Use Effect Setup add listener');

            App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
                // Example url: https://beerswift.app/tabs/tab2
                // slug = /tabs/tab2
                const slug = event.url.split('.app').pop();
                console.log('appUrlOpen', slug);
                // if (slug) {
                // //   history.push(slug);
                // }
                // If no match, do nothing - let regular routing
                // logic take over
            });



            App.addListener('appStateChange', ({ isActive }) => {
                console.log('App state changed. Is active?', isActive);
            });

            //   App.addListener('appUrlOpen', data => {
            //     console.log('App opened with URL:', data);
            //   });

            App.addListener('appRestoredResult', data => {
                console.log('Restored state:', data);
            });

            const checkAppLaunchUrl = async () => {
                const { url } = await App.getLaunchUrl();

                console.log('App opened with URL: ' + url);
            };

        }
    }, []);

    return null;
};

export default AppUrlListener;