import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
const ChangePassword = lazy(() => import('./ChangePassword'))
const Profile = lazy(() => import('./Profile'))
const EditProfile = lazy(() => import('./EditProfile'))

export const ACCT_PATH = {
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/profile/change-password',
  EDIT_PROFILE: '/profile/edit-profile',
}

const AcctRoutes:RouteObject[] = [
  {
    path: "",
    element: <Profile />
  },
  {
    path: "change-password" ,
    element: <ChangePassword />
  },
  {
    path: "edit-profile",
    element: <EditProfile />
  }
]


export default AcctRoutes
