
import React, { useEffect } from "react"
import { useNavigate } from "react-router"
import { navigationObservable } from '../helper/navigationSubjectHelper';
//to subscribe the navigation subject help on navigating
const NavigationProvider = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigationObservable().subscribe(x => {
      console.log("naivigation : ", x);
        if (x) {
          navigate(x);
        }
      })
  }, [])
  return (
    <>
     
    </>
  )
}
export default NavigationProvider