import { BehaviorSubject } from 'rxjs';
export interface UserProfile{
    ID?: string
    avatar?: string
    contactNo?: string
    email?: string
    name?: string
    userPermission?: number[]
    superUser?: boolean
}

let userProfile:UserProfile = {} as UserProfile;
let userProfileSubject = new BehaviorSubject<UserProfile>(userProfile);
let updateUserProfileSubject = new BehaviorSubject<boolean>(false);

export const setUserProfile = (data: UserProfile) => {
  userProfile = Object.assign({},data);
  userProfileSubject.next(userProfile);
};

export const getUserProfile = ():UserProfile => {
  return userProfile;
};

export const getUserProfileObservable = ():BehaviorSubject<UserProfile> => {
  return userProfileSubject;
};

export const updateUserProfileObservable = ():BehaviorSubject<boolean> => {
  return updateUserProfileSubject;
};

export const setGetUserProfile = ():void => {
  updateUserProfileSubject.next(true);
};