import React, { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import NewsRoutes from '../News/Routes'
import AppointmentRoutes from '../Appointment/Routes'
import NotificationRoutes from '../Notification/Routes'

// const HomeList = lazy(() => import('./HomeListing'))
const HomeList = lazy(() => import('./Home'))

export const HOME_PATH = {
  HOME_LIST: '/home',
}

const HomeRoutes: RouteObject[] = [
  {
    path: '',
    children: [
      { path: '', element: <HomeList /> },
      ...NewsRoutes,
      ...NotificationRoutes,
      ...AppointmentRoutes,
    ],
  },
]

export default HomeRoutes
